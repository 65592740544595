@import "react-alice-carousel/lib/alice-carousel.css";
.sa{
    color: wheat;
}
.img{
    width: 100%;
    height: 70vh;
    object-fit: cover;
}
.img1{
    width: 270px;
    height: 270px;
    object-fit: cover;
    margin: 10px;
}
.Accordionn{
    margin-top: 20px;
}
.css123{
 box-shadow: none;
}
.acc{
    margin-top: 10px;
}
.acc2{
    color: white;
}
.blue{
    color: rgb(81, 53, 206);
}
.link{
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    text-align: center;
}
.NavItemm{
    text-align: center;
    margin-top: 10px;

}
.Navbar{
    margin: 15px 0;
}
.Nav11{
  border-bottom: 1px solid #000;
}
.card1{
   margin: 20px 0;
}
.center{
    text-align: center;
}
.btn1{
 background-color: rgb(202, 110, 255);
}
.btn1:focus{
    background-color: rgb(252, 7, 219);
    transform: scale(1.2);
    transition: .5s ease-in;
    box-shadow: none;
}
.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798;
display: none; }
.Card_bodyy{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.alice-carousel__dots-item:not(.__custom) {
    width: 10px;
    height: 10px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #d1beff; }
    .alice-carousel__dots-item:not(.__custom):not(:last-child) {
      margin-right: 20px; }
    .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
 background-color: rgb(202, 110, 255);
  }
